import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';
import { data } from './../data';

const Rezidential = (props) => {
  const { data: dataPrice } = props;
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {data.categories[1].text.map((info, index) => {
        return (
          <Card className={classes.card} key={index}>
            <CardContent className={classes.content}>
              <>
                {index === 5 ? (
                  <>
                    <div className={classes.picture}>
                      <img src={`${process.env.PUBLIC_URL}/images/rezidential/saltele.png`} alt={info.imgName} className={classes.img} loading='lazy' />
                    </div>
                    <section>
                      <Typography className={classes.title}>{info.title}</Typography>
                      <Divider className={classes.divider} />
                      <Typography className={classes.desc}>{info.children[0].desc}</Typography>
                      <Typography className={classes.price} style={{ paddingTop: '10px' }}>
                        De la: {dataPrice?.saltea['1pers']} Lei
                      </Typography>
                      <Divider className={classes.divider} />
                      <Typography className={classes.desc}>{info.children[1].desc}</Typography>
                      <Typography className={classes.price} style={{ paddingTop: '10px' }}>
                        De la: {dataPrice?.saltea['2pers']} Lei
                      </Typography>
                    </section>
                  </>
                ) : (
                  <>
                    <div className={classes.picture}>
                      <img src={`${process.env.PUBLIC_URL}/images/rezidential/${info.imgName}`} alt={info.imgName} className={classes.img} loading='lazy' />
                    </div>
                    <section>
                      <Typography className={classes.title}>{info.title}</Typography>
                      <Typography className={classes.desc}>{info.desc}</Typography>
                      <Divider className={classes.divider} />
                      <Typography className={classes.price}>
                        De la: {dataPrice && dataPrice[info.id]} Lei{info.id === 'covoareMocheta' ? '/mp' : null}
                      </Typography>
                    </section>
                  </>
                )}
              </>
            </CardContent>
          </Card>
        );
      })}
    </div>
  );
};

export default Rezidential;
