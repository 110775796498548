import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

const Comercial = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapperFull}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <>
            <picture className={classes.picture}>
              <img
                src={`${process.env.PUBLIC_URL}/images/spatii-comerciale.png`}
                alt='spatii comerciale'
                className={classes.img}
                loading="lazy"
              />
            </picture>
            <section>
              <Typography className={classes.title}>
                Birouri, Săli de sport, Cinematografe, Cabinete medicale, Farmacii, Spații horeca
              </Typography>
              <Divider className={classes.divider} />
              <Typography className={classes.desc}>
                Tarifele se stabilesc individual pentru fiecare beneficiar în
                funcție de particularitatea și destinația spațiului
              </Typography>
            </section>
          </>
        </CardContent>
      </Card>
    </div>
  );
};

export default Comercial;
