import { Backdrop } from '@material-ui/core';
import { createMuiTheme } from '@material-ui/core/styles';

const Theme = createMuiTheme({
  palette: {
    primary: {
      main: '#47A8EA',
      second: '#47474C'
    },

    secondary: {
      main: '#94D60A',
      contrastText: '#fff',
      dark: '#83BE0A',
    },
  },

  typography: {
    fontFamily: `"Montserrat", "Helvetica", "Arial", sans-serif`,
    fontSize: 14,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
  },

  overrides: {
    MuiContainer: {
      root: {
        paddingLeft: '35px',
        paddingRight: '35px',
      },
    },
  },
});

export default Theme;
