import React, { useState } from "react";
import FsLightbox from "fslightbox-react";
import Container from "@material-ui/core/Container";
import CardMedia from "@material-ui/core/CardMedia";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  certifications: {
    background: theme.palette.primary.second,
    marginTop: "50px",
    marginBottom: "90px",

    [theme.breakpoints.down("sm")]: {
      marginTop: "90px",
    },
  },

  papperContainer: {
    position: "relative",
  },

  papper: {
    position: "absolute",
    width: "115px",
    height: "165px",
    background: "#fff",
    border: "1px solid #707070",

    [theme.breakpoints.up("sm")]: {
      width: "175px",
      height: "250px",
    },
  },

  cert1: {
    left: 0,
    top: "-25px",
  },

  cert2: {
    left: "125px",
    top: "-25px",

    [theme.breakpoints.up("sm")]: {
      left: "185px",
    },
  },

  media: {
    height: "100%",
    "&:hover": {
      cursor: "pointer",
    },
  },

  content: {
    color: "#fff",
    padding: "150px 0 25px 0",

    [theme.breakpoints.up("sm")]: {
      padding: "95px 0 95px 30px",
    },

    "& h3": {
      margin: "0 0 10px 0",
      padding: 0,
      fontSize: '24px',
      fontWeight: 700,
      [theme.breakpoints.up('sm')]: {
        fontSize: '40px',
      },
    },

    "& p": {
      fontSize: "18px",
      fontWeight: 500,

      [theme.breakpoints.up('sm')]: {
        fontSize: '20px',
        fontWeight: 700
      },
      margin: 0,
    },
  }
}));

const Certifications = () => {
  const classes = useStyles();
  // const [toggler, setToggler] = useState(false);
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  return (
    <section className={classes.certifications}>
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={12} sm={6} md={4}>
            <div className={classes.papperContainer}>
              <div className={`${classes.papper} ${classes.cert1}`}>
                <CardMedia
                  className={classes.media}
                  image={`${process.env.PUBLIC_URL}/images/certificari/DSP-Aeromicroflora-thumb.jpg`}
                  title="DSP Aeromicroflora"
                  onClick={() => openLightboxOnSlide(1)}
                />
              </div>

              <div className={`${classes.papper} ${classes.cert2}`}>
                <CardMedia
                  className={classes.media}
                  image={`${process.env.PUBLIC_URL}/images/certificari/DSP-Suprafete-thumb.jpg`}
                  title="DSP Suprafete"
                  onClick={() => openLightboxOnSlide(2)}
                />
              </div>

              <FsLightbox
                // toggler={toggler}
                toggler={lightboxController.toggler}
                sources={[
                  `${process.env.PUBLIC_URL}/images/certificari/DSP-Aeromicroflora.png`,
                  `${process.env.PUBLIC_URL}/images/certificari/DSP-Suprafete.png`,
                ]}
                slide={lightboxController.slide}
              />
            </div>
          </Grid>

          <Grid item xs={12} sm={6} md={8}>
            <div className={classes.content}>
              <h3>Certificări</h3>
              <p>Servicii atestate DSP in condiții de laborator</p>
            </div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Certifications;
