import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const heroBg = `${process.env.PUBLIC_URL}/images/hero.png`;
const heroBgMobile = `${process.env.PUBLIC_URL}/images/hero-mobile.png`;

const useStyles = makeStyles((theme) => ({
  hero: {
    display: 'flex',
    height: '610px',
    background: `center bottom no-repeat url(${heroBgMobile})`,
    backgroundSize: 'cover',
    paddingTop: '130px',

    [theme.breakpoints.up('sm')]: {
      height: '830px',
      background: `url(${heroBg}) no-repeat bottom center`,
    },
  },

  body: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    margin: '0',
    maxWidth: '70%',
    
    '& > div': {
      [theme.breakpoints.down('sm')]: {
        marginTop: '-50px'
      },
    },

    [theme.breakpoints.down('sm')]: {
      maxWidth: '84%',
    },

    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
    },
  },

  title: {
    color: '#47A8EA',
    textTransform: 'uppercase',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '30px',
    margin: '0 0 30px',

    [theme.breakpoints.up('sm')]: {
      fontSize: '50px',
      lineHeight: '60px',
      maxWidth: '550px',
      marginBottom: '60px',
    },
  },

  subtitle: {
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '700',
    maxWidth: '470px',
    color: '#47474C',
    margin: '0',

    [theme.breakpoints.up('sm')]: {
      fontSize: '22px',
      lineHeight: '32px',
    },
  },
}));

const Hero = () => {
  const classes = useStyles();

  return (
    <section className={classes.hero}>
      <div className={classes.body}>
        <Container>
          <Grid container>
            <Grid item>
              <h2 className={classes.title}>
                Servicii ecologice superioare de curățenie
              </h2>
              <p className={classes.subtitle}>
                Dezinfectare și igienizare cu abur uscat, garantate de sistemul
                inovator de ultimă generație "twist10-S"
              </p>
            </Grid>
          </Grid>
        </Container>
      </div>
    </section>
  );
};

export default Hero;
