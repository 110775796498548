import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Footer from './Footer';
import Navbar from './Navbar';

const useStyles = makeStyles((theme) => ({
  gdpr: {
    marginTop: '130px',
    paddingTop: '50px',
    background: '#fff',
  },

  title: {
    fontSize: '30px',
    lineHeight: '40px',
    fontWeight: 700,
    color: '#47474C',
    textTransform: 'uppercase',
    margin: '0 0 50px 0',

    [theme.breakpoints.up('sm')]: {
      fontSize: '40px',
      lineHeight: '50px',
    },
  },

  paragraph: {
    fontSize: '16px',
    lineHeight: '28px',
    paddingBottom: '40px',
    fontWeight: 500,

    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
      lineHeight: '32px',
    },

    '& a': {
    display: 'block',
    paddingTop: '20px',
    color: '#47474C'
    }
  },
}));

const Gdpr = () => {
  const classes = useStyles();

  return (
    <>
      <Navbar />

      <section className={classes.gdpr}>
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <h3 className={classes.title}>Despre cookies</h3>
            </Grid>

            <Grid item xs={12}>
              <Typography className={classes.paragraph}>
                Site-ul www.alsoclean.ro foloseste cookie-uri proprii
                (first-party cookie) și de la terți (third-party cookie) pentru
                a furniza vizitatorilor o experiență de navigare superioară și
                servicii adaptate nevoilor specifice ale acestora.
              </Typography>

              <Typography className={classes.paragraph}>
                Cu ajutorul fișierelor de tip cookie, deținătorul website-ului
                poate îmbunătăți interacțiuniea utilizatorilor cu website-ul.
              </Typography>
              <Typography className={classes.paragraph}>
                Acestea sunt fișiere de mici dimensiuni, formate din litere și
                numere, care pot fi stocate pe computerul, terminalul mobil sau
                alte echipamente ale unui utilizator de pe care se accesează
                Internetul. Fișierele de tip cookie ajută la servicii web mai
                bune și au ca rezultat navigarea prietenoasă și personalizată a
                fiecărui utilizator.
              </Typography>
              <Typography className={classes.paragraph}>
                Fișierele de tip cookie NU sunt viruși! Nu sunt alcătuite din
                bucăți de cod, așa că nu pot fi executate, nici nu pot auto-rula
                pe sistemul tau de operare.
              </Typography>
              <Typography className={classes.paragraph}>
                În cazul în care doriți să eliminați fișierele de tip cookie
                stocate pe dispozitivele dvs. și să configurați browserul dvs.
                web astfel încât să refuze fișiere de tip cookie, puteți să
                utilizați setările privind preferințele din browser-ul dvs. web.
              </Typography>
              <Typography className={classes.paragraph}>
                Dacă doriți să aflați mai multe infromații despre fișierele de
                tip cookie, cum sunt utilzate și la ce sunt utilizate, vă
                recomandăm să parcurgeți mai multe informații în secțiunea
                dedicată: Politica de utilizare a fișierelor de tip Cookie,
                precum și la urmatoarele link-uri: <a href="//www.allaboutcookies.org" rel="noreferrer" target="_blank">www.allaboutcookies.org</a>
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </section>

      <Footer />
    </>
  );
};

export default Gdpr;
