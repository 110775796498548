import { NavLink } from 'react-router-dom';

const Logo = () => {
  return (
    <div className='logo'>
      <NavLink to='/'>
        <svg
          id='Group_4'
          data-name='Group 4'
          xmlns='http://www.w3.org/2000/svg'
          width='127.583'
          height='64.684'
          viewBox='0 0 127.583 64.684'
        >
          <circle
            id='Ellipse_1'
            data-name='Ellipse 1'
            cx='0.841'
            cy='0.841'
            r='0.841'
            transform='translate(120.079 6.748)'
            fill='#47a8ea'
          />
          <circle
            id='Ellipse_2'
            data-name='Ellipse 2'
            cx='2.349'
            cy='2.349'
            r='2.349'
            transform='translate(121.311 0)'
            fill='#47a8ea'
          />
          <circle
            id='Ellipse_3'
            data-name='Ellipse 3'
            cx='1.591'
            cy='1.591'
            r='1.591'
            transform='translate(121.718 1.128)'
            fill='#fff'
          />
          <g
            id='Group_1'
            data-name='Group 1'
            transform='translate(1.457 48.125)'
          >
            <path
              id='Path_1'
              data-name='Path 1'
              d='M316.986,852.707a2.223,2.223,0,0,1,2.395-2.395h8.5a3.1,3.1,0,0,1,.322.747,3.339,3.339,0,0,1,.142.979,1.884,1.884,0,0,1-.425,1.391,1.54,1.54,0,0,1-1.12.412h-5.434v2.395h5.794a3.136,3.136,0,0,1,.322.734,3.213,3.213,0,0,1,.142.966,1.923,1.923,0,0,1-.412,1.391,1.5,1.5,0,0,1-1.107.412h-4.738v2.7h6.618a3.108,3.108,0,0,1,.322.747,3.339,3.339,0,0,1,.142.979,1.933,1.933,0,0,1-.425,1.4,1.515,1.515,0,0,1-1.12.425h-7.52a2.223,2.223,0,0,1-2.395-2.395Z'
              transform='translate(-316.986 -849.823)'
              fill='#47474c'
            />
            <path
              id='Path_2'
              data-name='Path 2'
              d='M499.019,847.512a3.815,3.815,0,0,0-3.039,1.21,5.047,5.047,0,0,0-1.056,3.425A4.759,4.759,0,0,0,496.1,855.6a4.186,4.186,0,0,0,3.155,1.21,4.983,4.983,0,0,0,1.79-.283,12.257,12.257,0,0,0,1.4-.644,2.564,2.564,0,0,1,.682.863,2.648,2.648,0,0,1,.245,1.172,2.11,2.11,0,0,1-1.172,1.79,6.852,6.852,0,0,1-3.567.734,10.531,10.531,0,0,1-3.245-.489,7.23,7.23,0,0,1-2.653-1.506,7.1,7.1,0,0,1-1.79-2.575,9.551,9.551,0,0,1-.657-3.721,9.24,9.24,0,0,1,.631-3.541,7.521,7.521,0,0,1,1.725-2.588,7.262,7.262,0,0,1,2.562-1.6,9.015,9.015,0,0,1,3.142-.541,7.229,7.229,0,0,1,3.67.773,2.275,2.275,0,0,1,1.3,1.983,2,2,0,0,1-.335,1.159,2.759,2.759,0,0,1-.773.773,8.392,8.392,0,0,0-1.429-.747A4.659,4.659,0,0,0,499.019,847.512Z'
              transform='translate(-477.104 -843.881)'
              fill='#47474c'
            />
            <path
              id='Path_3'
              data-name='Path 3'
              d='M680.182,852.147a9.339,9.339,0,0,1,.631-3.554,7.165,7.165,0,0,1,4.3-4.172,9.775,9.775,0,0,1,6.412,0,7.466,7.466,0,0,1,2.588,1.584,7.281,7.281,0,0,1,1.738,2.588,9.344,9.344,0,0,1,.631,3.554,9.616,9.616,0,0,1-.618,3.567,7.2,7.2,0,0,1-1.713,2.6,7.314,7.314,0,0,1-2.588,1.584,9.464,9.464,0,0,1-3.245.541,9.264,9.264,0,0,1-3.245-.554,7.231,7.231,0,0,1-2.575-1.609,7.439,7.439,0,0,1-1.7-2.6A9.44,9.44,0,0,1,680.182,852.147Zm4.635,0a5.43,5.43,0,0,0,.953,3.5,3.412,3.412,0,0,0,5.125,0,6.906,6.906,0,0,0,.013-6.979,3.123,3.123,0,0,0-2.562-1.159,3.184,3.184,0,0,0-2.562,1.146A5.325,5.325,0,0,0,684.817,852.147Z'
              transform='translate(-652.55 -843.881)'
              fill='#47474c'
            />
            <path
              id='Path_4'
              data-name='Path 4'
              d='M994.562,847.512a3.815,3.815,0,0,0-3.039,1.21,5.047,5.047,0,0,0-1.056,3.425,4.759,4.759,0,0,0,1.172,3.451,4.186,4.186,0,0,0,3.155,1.21,4.983,4.983,0,0,0,1.79-.283,12.25,12.25,0,0,0,1.4-.644,2.563,2.563,0,0,1,.682.863,2.647,2.647,0,0,1,.245,1.172,2.11,2.11,0,0,1-1.172,1.79,6.852,6.852,0,0,1-3.567.734,10.531,10.531,0,0,1-3.245-.489,7.229,7.229,0,0,1-2.653-1.506,7.1,7.1,0,0,1-1.79-2.575,9.551,9.551,0,0,1-.657-3.721,9.239,9.239,0,0,1,.631-3.541,7.519,7.519,0,0,1,1.725-2.588,7.261,7.261,0,0,1,2.562-1.6,9.016,9.016,0,0,1,3.142-.541,7.23,7.23,0,0,1,3.67.773,2.275,2.275,0,0,1,1.3,1.983,2,2,0,0,1-.335,1.159,2.757,2.757,0,0,1-.772.773,8.39,8.39,0,0,0-1.429-.747A4.658,4.658,0,0,0,994.562,847.512Z'
              transform='translate(-934.946 -843.881)'
              fill='#47474c'
            />
            <path
              id='Path_5'
              data-name='Path 5'
              d='M1191.658,862.868a2.223,2.223,0,0,1-2.4-2.395V847.159q.283-.051.9-.142a8.237,8.237,0,0,1,1.185-.09,5.378,5.378,0,0,1,1.043.09,1.858,1.858,0,0,1,.76.322,1.392,1.392,0,0,1,.464.644,3.14,3.14,0,0,1,.155,1.082v10.172h5.9a3.392,3.392,0,0,1,.335.76,3.194,3.194,0,0,1,.154.991,1.979,1.979,0,0,1-.438,1.455,1.594,1.594,0,0,1-1.159.425Z'
              transform='translate(-1122.899 -846.695)'
              fill='#47474c'
            />
            <path
              id='Path_6'
              data-name='Path 6'
              d='M1362.9,852.707a2.223,2.223,0,0,1,2.4-2.395h8.5a3.1,3.1,0,0,1,.322.747,3.337,3.337,0,0,1,.142.979,1.884,1.884,0,0,1-.425,1.391,1.54,1.54,0,0,1-1.12.412h-5.434v2.395h5.794a3.142,3.142,0,0,1,.322.734,3.216,3.216,0,0,1,.141.966,1.924,1.924,0,0,1-.412,1.391,1.5,1.5,0,0,1-1.107.412h-4.738v2.7h6.618a3.11,3.11,0,0,1,.322.747,3.341,3.341,0,0,1,.141.979,1.932,1.932,0,0,1-.425,1.4,1.515,1.515,0,0,1-1.12.425h-7.52a2.223,2.223,0,0,1-2.4-2.395Z'
              transform='translate(-1283.328 -849.823)'
              fill='#47474c'
            />
            <path
              id='Path_7'
              data-name='Path 7'
              d='M1542.972,847.648a5.461,5.461,0,0,1,1.326-.489,7.06,7.06,0,0,1,1.79-.232,6.447,6.447,0,0,1,2.279.373,1.986,1.986,0,0,1,1.275,1.172q.464,1.339,1,3.116t1.082,3.67q.541,1.893,1.056,3.734t.9,3.258a2.122,2.122,0,0,1-.927.579,4.413,4.413,0,0,1-1.468.219,4.521,4.521,0,0,1-1.043-.1,1.852,1.852,0,0,1-.7-.309,1.514,1.514,0,0,1-.438-.541,4.619,4.619,0,0,1-.3-.773l-.463-1.777h-4.661q-.207.773-.412,1.6t-.412,1.6a6.633,6.633,0,0,1-.76.219,4.462,4.462,0,0,1-.966.09,3.369,3.369,0,0,1-1.944-.451,1.449,1.449,0,0,1-.631-1.249,2.61,2.61,0,0,1,.1-.721q.1-.36.232-.85.18-.7.5-1.725t.708-2.227q.386-1.2.824-2.459t.824-2.382q.386-1.12.708-2T1542.972,847.648Zm3.09,3.322q-.361,1.082-.811,2.408t-.811,2.691h3.193q-.361-1.391-.734-2.717t-.682-2.382Z'
              transform='translate(-1445.629 -846.695)'
              fill='#47474c'
            />
            <path
              id='Path_8'
              data-name='Path 8'
              d='M1779.155,862.563a2.353,2.353,0,0,1-1.043.554,6.061,6.061,0,0,1-1.635.193,5.669,5.669,0,0,1-1.8-.283,2.5,2.5,0,0,1-1.391-1.365l-2.549-5.2q-.387-.8-.7-1.507t-.67-1.635l-.155.026q.1,1.391.116,2.833t.013,2.833V863a7.174,7.174,0,0,1-.863.154,8.666,8.666,0,0,1-1.146.077,5.823,5.823,0,0,1-1-.077,1.535,1.535,0,0,1-.721-.309,1.484,1.484,0,0,1-.438-.631,2.937,2.937,0,0,1-.154-1.043V848.3a2.154,2.154,0,0,1,1.107-.773,4.871,4.871,0,0,1,1.6-.257,5.56,5.56,0,0,1,1.816.3,2.459,2.459,0,0,1,1.378,1.352l2.575,5.2q.386.8.7,1.506t.67,1.635l.129-.026q-.1-1.391-.129-2.781t-.026-2.781V847.5a7.15,7.15,0,0,1,.863-.155,8.649,8.649,0,0,1,1.146-.077,3.091,3.091,0,0,1,1.725.4,1.892,1.892,0,0,1,.592,1.661Z'
              transform='translate(-1654.849 -847.008)'
              fill='#47474c'
            />
          </g>
          <g id='Group_3' data-name='Group 3' transform='translate(0 4.414)'>
            <g id='Group_2' data-name='Group 2'>
              <path
                id='Path_9'
                data-name='Path 9'
                d='M307.462,277.58a11.938,11.938,0,0,1,2.9-1.07,15.448,15.448,0,0,1,3.915-.507,14.105,14.105,0,0,1,4.985.817,4.344,4.344,0,0,1,2.788,2.563q1.014,2.93,2.2,6.816t2.366,8.027q1.183,4.14,2.31,8.168t1.972,7.126a4.641,4.641,0,0,1-2.028,1.267,9.655,9.655,0,0,1-3.211.479,9.877,9.877,0,0,1-2.281-.225,4.057,4.057,0,0,1-1.521-.676,3.32,3.32,0,0,1-.958-1.183,10.151,10.151,0,0,1-.648-1.69l-1.014-3.887h-10.2q-.451,1.69-.9,3.492t-.9,3.492a14.5,14.5,0,0,1-1.662.479,9.746,9.746,0,0,1-2.112.2,7.369,7.369,0,0,1-4.253-.986,3.169,3.169,0,0,1-1.38-2.732,5.715,5.715,0,0,1,.225-1.577q.224-.788.507-1.859.393-1.521,1.1-3.774t1.549-4.872q.845-2.62,1.8-5.38t1.8-5.21q.845-2.45,1.549-4.365T307.462,277.58Zm6.759,7.266q-.789,2.366-1.774,5.267T310.673,296h6.985q-.789-3.041-1.605-5.943t-1.493-5.21Z'
                transform='translate(-297.83 -275.496)'
                fill='#47a8ea'
              />
              <path
                id='Path_10'
                data-name='Path 10'
                d='M779.872,310.87a4.862,4.862,0,0,1-5.239-5.239V276.51q.619-.112,1.971-.31a18.023,18.023,0,0,1,2.591-.2,11.765,11.765,0,0,1,2.281.2,4.064,4.064,0,0,1,1.662.7,3.045,3.045,0,0,1,1.014,1.408,6.869,6.869,0,0,1,.338,2.366v22.25h12.9a7.44,7.44,0,0,1,.732,1.662,6.992,6.992,0,0,1,.338,2.169,4.329,4.329,0,0,1-.958,3.183,3.486,3.486,0,0,1-2.535.929Z'
                transform='translate(-738.358 -275.496)'
                fill='#47a8ea'
              />
              <path
                id='Path_11'
                data-name='Path 11'
                d='M1128.516,292.339q-2.479-.845-4.506-1.718a13.805,13.805,0,0,1-3.492-2.112,8.736,8.736,0,0,1-2.281-2.957,9.717,9.717,0,0,1-.817-4.2,9.219,9.219,0,0,1,3.69-7.661q3.689-2.873,10.336-2.873a28.207,28.207,0,0,1,4.506.338,13.452,13.452,0,0,1,3.577,1.042,6.24,6.24,0,0,1,2.338,1.8,4.084,4.084,0,0,1,.845,2.563,4.5,4.5,0,0,1-.676,2.507,7.011,7.011,0,0,1-1.633,1.774,12.753,12.753,0,0,0-3.323-1.38,16.668,16.668,0,0,0-4.563-.591,7.528,7.528,0,0,0-3.718.7,2.054,2.054,0,0,0-1.183,1.774,1.638,1.638,0,0,0,.732,1.38,7.565,7.565,0,0,0,2.2.986l2.985.958a21.616,21.616,0,0,1,8.14,4.309,9.211,9.211,0,0,1,2.845,7.126,9.374,9.374,0,0,1-3.774,7.745q-3.774,2.957-11.1,2.957a25.824,25.824,0,0,1-4.816-.423,15.21,15.21,0,0,1-3.887-1.239,7.166,7.166,0,0,1-2.591-2.028,4.452,4.452,0,0,1-.929-2.788,4.224,4.224,0,0,1,.958-2.788,7.306,7.306,0,0,1,2.084-1.774,15.289,15.289,0,0,0,3.859,2.141,13.444,13.444,0,0,0,4.985.9,6.691,6.691,0,0,0,3.887-.845,2.436,2.436,0,0,0,1.127-1.971,1.931,1.931,0,0,0-.9-1.718,11.7,11.7,0,0,0-2.535-1.155Z'
                transform='translate(-1055.064 -270.709)'
                fill='#47a8ea'
              />
              <path
                id='Path_12'
                data-name='Path 12'
                d='M1506.113,287.422a20.424,20.424,0,0,1,1.38-7.773,15.672,15.672,0,0,1,9.407-9.125,21.381,21.381,0,0,1,14.026,0,16.327,16.327,0,0,1,5.661,3.464,15.924,15.924,0,0,1,3.8,5.661,20.436,20.436,0,0,1,1.38,7.773,21.031,21.031,0,0,1-1.352,7.8,15.746,15.746,0,0,1-3.746,5.689,16,16,0,0,1-5.661,3.464,20.708,20.708,0,0,1-7.1,1.183,20.26,20.26,0,0,1-7.1-1.211,15.813,15.813,0,0,1-5.633-3.521,16.265,16.265,0,0,1-3.718-5.689A20.645,20.645,0,0,1,1506.113,287.422Zm10.139,0q0,5.126,2.084,7.661a7.464,7.464,0,0,0,11.209,0q2.084-2.535,2.084-7.661,0-5.07-2.056-7.6a6.831,6.831,0,0,0-5.6-2.535,6.965,6.965,0,0,0-5.6,2.507Q1516.252,282.3,1516.252,287.422Z'
                transform='translate(-1414.186 -269.341)'
                fill='#47a8ea'
              />
            </g>
          </g>
          <path
            id='Path_13'
            data-name='Path 13'
            d='M759.745,949.857a.167.167,0,0,0-.192.232,2.6,2.6,0,0,1,.229,1.231c-.032.589-.113,1.676,1.961,2.063a1.921,1.921,0,0,1,.249,1.238c0,.054.435-.048.449-.1a4.937,4.937,0,0,1,.708-.993c2.725.242,3-1.142,3.171-1.889a3.354,3.354,0,0,1,.782-1.53.167.167,0,0,0-.117-.279,6.748,6.748,0,0,0-2.992.511,2.76,2.76,0,0,0-1.276,2.445,4.011,4.011,0,0,1,2.229-1.134,4.486,4.486,0,0,0-2.086,1.425,5.217,5.217,0,0,0-.569.927,3.712,3.712,0,0,0-.24-.9,3.46,3.46,0,0,0-1.252-1.492,3.094,3.094,0,0,1,1.419,1.306,2.129,2.129,0,0,0-.438-2.082A5.136,5.136,0,0,0,759.745,949.857Z'
            transform='translate(-724.41 -893.637)'
            fill='#94d60a'
          />
          <path
            id='Path_14'
            data-name='Path 14'
            d='M760.788,952.27h-.012a.121.121,0,0,1-.12-.108,1.372,1.372,0,0,1,0-.174,1.579,1.579,0,0,0-.215-.98c-2.1-.408-2.033-1.535-2-2.142v-.018a2.5,2.5,0,0,0-.22-1.183.269.269,0,0,1,.31-.373h0a5.26,5.26,0,0,1,2.077,1,2.246,2.246,0,0,1,.471,2.18l-.055.251-.132-.221a3.563,3.563,0,0,0-.2-.3q.107.18.21.386a5.194,5.194,0,0,1,.2.62,6.889,6.889,0,0,1,.439-.665,6.679,6.679,0,0,1,.8-.762,4.688,4.688,0,0,0-.792.6l-.187.177.015-.257a2.884,2.884,0,0,1,1.334-2.531,6.826,6.826,0,0,1,3.04-.52.269.269,0,0,1,.188.449,3.241,3.241,0,0,0-.758,1.484l-.005.022c-.169.761-.483,2.175-3.219,1.95-.115.146-.2.247-.259.325a2.988,2.988,0,0,0-.393.589l-.014.024h0C761.223,952.222,760.873,952.27,760.788,952.27Zm-2.319-4.69a.064.064,0,0,0-.049.024.063.063,0,0,0-.009.069,2.7,2.7,0,0,1,.238,1.279v.018c-.032.588-.086,1.572,1.879,1.94l.044.008.024.039a1.787,1.787,0,0,1,.268,1.136c0,.023,0,.044,0,.064a1.167,1.167,0,0,0,.266-.072l.007-.012a3.157,3.157,0,0,1,.411-.615c.068-.084.16-.2.292-.366l.034-.043.055,0c2.608.232,2.89-1.032,3.057-1.787l.005-.022a3.444,3.444,0,0,1,.806-1.577.063.063,0,0,0,.012-.069.064.064,0,0,0-.058-.041,6.633,6.633,0,0,0-2.945.5,2.473,2.473,0,0,0-1.2,2.107,4.022,4.022,0,0,1,2.1-.989l.972-.056-.939.256a4.321,4.321,0,0,0-2.037,1.4,5.18,5.18,0,0,0-.551.9l-.155.349-.039-.379a3.658,3.658,0,0,0-.234-.871,3.3,3.3,0,0,0-1.209-1.44l-.836-.5.921.317a3.042,3.042,0,0,1,1.333,1.133,1.85,1.85,0,0,0-.451-1.735,5.053,5.053,0,0,0-1.993-.961h0Z'
            transform='translate(-723.176 -891.264)'
            fill='#fff'
          />
        </svg>
      </NavLink>
    </div>
  );
};

export default Logo;
