const data = {
  categories: [
    {
      title: 'auto',
      label: 'auto',
      text: []
    },
    {
      title: 'rezidențial',
      label: 'rezidential',
      content: 'rezidential tab',
      text: [
        {
          id: 'scaun',
          title: 'scaun',
          desc: 'Curățare, igienizare, dezinfectare cu abur uscat',
          imgName: 'scaun.png'
        },
        {
          id: 'fotoliu',
          title: 'fotoliu',
          desc: 'Curățare, igienizare, dezinfectare cu abur uscat',
          price: 'De la: 50 Lei',
          imgName: 'fotoliu.png'
        },
        {
          id: 'canapea2Loc',
          title: 'canapea 2 locuri',
          desc: 'Curățare, igienizare, dezinfectare cu abur uscat',
          imgName: 'canapea-2-locuri.png'
        },
        {
          id: 'canapea3Loc',
          title: 'canapea 3 locuri',
          desc: 'Curățare, igienizare, dezinfectare cu abur uscat',
          imgName: 'canapea-3-locuri.png'
        },
        {
          id: 'coltar',
          title: 'colțar',
          desc: 'Curățare, igienizare, dezinfectare cu abur uscat',
          imgName: 'coltar.png'
        },
        {
          id: 'saltele',
          title: 'saltele',
          imgName: 'saltele.png',
          children: [
            { desc: '1 Persoană', id: '1pers' },
            { desc: '2 Persoane', id: '2pers' }
          ]
        },
        {
          id: 'covoareMocheta',
          title: 'covor / mochetă',
          desc: 'Curățare, igienizare, dezinfectare cu abur uscat',
          imgName: 'covor.png'
        }
      ]
    },
    {
      title: 'spații comerciale',
      label: 'comercial',
      content: 'spatii tab',
      text: []
    },
    {
      title: 'ambarcațiuni și avioane',
      label: 'barci',
      content: 'barci tab',
      text: []
    }
  ]
};

export { data };
