import { scroller } from 'react-scroll';
import { NavLink, useHistory } from 'react-router-dom';
import CookieConsent from 'react-cookie-consent';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  footer: {
    background: '#fff',
    padding: '70px 0 90px 0',
    color: '#444',

    [theme.breakpoints.up('sm')]: {
      padding: '110px 0 140px 0',
    },
  },

  col: {
    paddingBottom: '45px',
  },

  headline: {
    fontSize: '22px',
    fontWeight: 700,
    margin: '0 0 60px 0',

    [theme.breakpoints.up('sm')]: {
      fontSize: '40px',
    },
  },
  title: {
    fontSize: '18x',
    fontWeight: 700,
    margin: '0 0 20px 0',

    [theme.breakpoints.up('sm')]: {
      fontSize: '22px',
    },
  },
  list: {
    listStyle: 'none',
    margin: 0,
    padding: 0,

    '& li': {
      fontSize: '16px',
      lineHeight: '22px',
      fontWeight: 500,
      display: 'flex',

      [theme.breakpoints.up('sm')]: {
        fontSize: '18px',
        lineHeight: '24px',
      },

      '&:not(:last-child)': {
        marginBottom: '15px',
      },

      '& a': {
        color: '#444',
        textDecoration: 'none',
        display: 'flex',

        '&:hover': {
          cursor: 'pointer',
        },
      },

      '& svg': {
        marginRight: '10px',
      },
    },
  },

  copyright: {
    marginTop: '70px',
    fontSize: '13px',
  },

  buttonLink: {
    background: 'none',
    border: 'none',
    padding: 0,
    outline: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  },
}));

const Footer = () => {
  const scrollToSection = (id, offsetTop) => {
    scroller.scrollTo(id, {
      duration: 800,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: parseInt(offsetTop),
    });
  };

  const classes = useStyles();
  const date = new Date();
  const history = useHistory();

  const routeTo = (id, outset) => {
    history.push('/');
    setTimeout(() => {
      scrollToSection(id, outset);
    }, 10);
  };

  return (
    <>
      <section className={classes.footer}>
        <Container>
          <Grid container spacing={0}>
            <Grid item xs={12}>
              <h3 className={classes.headline}>Also Eco Clean</h3>
            </Grid>
          </Grid>

          <Grid container spacing={10}>
            <Grid item xs={12} sm={12} md={4} className={classes.col}>
              <h4 className={classes.title}>Aducem prospețimea la tine</h4>
              <ul className={classes.list}>
                <li>
                  <button
                    className={classes.buttonLink}
                    onClick={() => routeTo('benefits', '-120')}
                  >
                    Beneficii
                  </button>
                </li>

                <li>
                  <button
                    className={classes.buttonLink}
                    onClick={() => routeTo('services', '-200')}
                  >
                    Servicii și tarife
                  </button>
                </li>

                <li>
                  <button
                    className={classes.buttonLink}
                    onClick={() => routeTo('videos', '-200')}
                  >
                    Video inainte și după
                  </button>
                </li>

                {/* <li>Certificări</li> */}
                <li>
                  <NavLink to='/gdpr' style={{ paddingLeft: 0 }}>
                    GDPR
                  </NavLink>
                </li>
              </ul>
            </Grid>

            <Grid item xs={12} sm={12} md={4} className={classes.col}>
              <h4 className={classes.title}>Contact</h4>
              <ul className={classes.list}>
                <li>Telefon: 0722 188 145</li>
                <li>Email: office@alsoclean.ro</li>
              </ul>
            </Grid>

            <Grid item xs={12} sm={12} md={4} className={classes.col}>
              <h4 className={classes.title}>Urmărește-ne pe:</h4>
              <ul className={classes.list}>
                <li>
                  <a
                    href='https://www.facebook.com/AlsoClean/'
                    rel='noreferrer'
                    target='_blank'
                  >
                    <FacebookIcon />
                    Facebook
                  </a>
                </li>
                <li>
                  <a
                    href='https://www.instagram.com/alsoecoclean/'
                    rel='noreferrer'
                    target='_blank'
                  >
                    <InstagramIcon />
                    Instagram
                  </a>
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container spacing={0} className={classes.copyright}>
            <Grid item xs={12}>
              <span>
                &copy; Copyright {date.getFullYear()} Also Eco Clean. Toate
                drepturile rezervate.
              </span>
            </Grid>
          </Grid>
        </Container>
      </section>

      <CookieConsent
        location='bottom'
        buttonText='Am inteles'
        cookieName='alsoGdpr2'
        style={{ background: '#fff', color: '#47474C', padding: '10px' }}
        buttonStyle={{
          color: '#fff',
          background: '#94D60A',
          padding: '12px 29px',
          fontSize: '14px',
          fontWeight: 700,
        }}
        expires={150}
      >
        Acest website folosește cookies pentru a asigura o experiență optimă de
        utilizare.{' '}
        <NavLink to='/gdpr' style={{ color: '#47474C' }}>
          Mai multe detalii
        </NavLink>
      </CookieConsent>
    </>
  );
};

export default Footer;
