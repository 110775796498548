import Benefits from './Benefits';
import Certifications from './Certifications';
import Footer from './Footer';
import Hero from './Hero';
import Navbar from './Navbar';
import Services from './Services';
import Videos from './Videos';

const Home = () => {
  return (
    <>
      <Navbar />
      <Hero />
      <Benefits />
      <Services />
      <Certifications />
      <Videos />
      <Footer />
    </>
  );
};

export default Home;
