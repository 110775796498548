import React from 'react';
import Divider from '@material-ui/core/Divider';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

const Auto = ({ data }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <>
            <div className={classes.picture}>
              <img src={`${process.env.PUBLIC_URL}/images/auto/clasa-mica.png`} alt='clasa mica' loading='lazy' className={classes.img} />
            </div>
            <section>
              <Typography className={classes.title}>clasa mica</Typography>
              <Divider className={classes.divider} />
              <p className={classes.desc}>
                <span>Interior full</span> - Tapițerie scaune, bord, fețe, uși, plafon
              </p>
              <Typography className={classes.price}>{data?.clasaMica.full} Lei</Typography>
              <Divider className={classes.divider} />
              <p className={classes.desc}>
                <span>Interior simplu</span> - doar tapițerie
              </p>
              <Typography className={classes.price}>{data?.clasaMica.simplu} Lei / loc</Typography>
            </section>
          </>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <>
            <div className={classes.picture}>
              <img src={`${process.env.PUBLIC_URL}/images/auto/clasa-familiala.png`} alt='clasa familila' loading='lazy' className={classes.img} />
            </div>
            <section>
              <Typography className={classes.title}>clasa familiala</Typography>
              <Divider className={classes.divider} />
              <p className={classes.desc}>
                <span>Interior full</span> - Tapițerie scaune, bord, fețe, uși, plafon
              </p>
              <Typography className={classes.price}>{data?.clasaFamiliala.full} Lei</Typography>
              <Divider className={classes.divider} />
              <p className={classes.desc}>
                <span>Interior simplu</span> - doar tapițerie
              </p>
              <Typography className={classes.price}>{data?.clasaFamiliala.simplu} Lei / loc</Typography>
            </section>
          </>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <>
            <div className={classes.picture}>
              <img src={`${process.env.PUBLIC_URL}/images/auto/suv.png`} alt='suv' loading='lazy' className={classes.img} />
            </div>
            <section>
              <Typography className={classes.title}>suv</Typography>
              <Divider className={classes.divider} />
              <p className={classes.desc}>
                <span>Interior full</span> - Tapițerie scaune, bord, fețe, uși, plafon
              </p>
              <Typography className={classes.price}>{data?.suv.full} Lei</Typography>
              <Divider className={classes.divider} />
              <p className={classes.desc}>
                <span>Interior simplu</span> - doar tapițerie
              </p>
              <Typography className={classes.price}>{data?.suv.simplu} Lei / loc</Typography>
            </section>
          </>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <>
            <div className={classes.picture}>
              <img src={`${process.env.PUBLIC_URL}/images/auto/microbuze.png`} alt='micobuze / autocare' loading='lazy' className={classes.img} />
            </div>
            <section>
              <Typography className={classes.title}>microbuze / autocare</Typography>
              <Divider className={classes.divider} />
              <Typography className={classes.desc}>
                <span>9 locuri</span>
                <Typography className={classes.price}>{data?.microbuzAutocar['9locuri']} Lei</Typography>
              </Typography>

              <Divider className={classes.divider} style={{ margin: '8px 0' }} />
              <Typography className={classes.desc}>
                <span>24 locuri</span>
                <Typography className={classes.price}>{data?.microbuzAutocar['24locuri']} Lei</Typography>
              </Typography>

              <Divider className={classes.divider} style={{ margin: '8px 0' }} />
              <Typography className={classes.desc}>
                <span>60 locuri</span>
                <Typography className={classes.price}>{data?.microbuzAutocar['60locuri']} Lei</Typography>
              </Typography>
            </section>
          </>
        </CardContent>
      </Card>

      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <>
            <div className={classes.picture}>
              <img src={`${process.env.PUBLIC_URL}/images/auto/camion.png`} alt='camion' loading='lazy' className={classes.img} />
            </div>
            <section>
              <Typography className={classes.title}>camion</Typography>
              <Divider className={classes.divider} />
              <p className={classes.desc}>
                <span>Cabina</span>
              </p>
              <Typography className={classes.price}>{data?.camion} Lei</Typography>
            </section>
          </>
        </CardContent>
      </Card>
    </div>
  );
};

export default Auto;
