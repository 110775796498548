import React from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import ReactPlayer from 'react-player';

const useStyles = makeStyles((theme) => ({
  videos: {
    paddingBottom: '100px',
  },

  title: {
    fontWeight: 700,
    fontSize: '24px',
    color: '#47474C',
    textTransform: 'uppercase',
    textAlign: 'center',
    margin: '0 0 50px 0',

    [theme.breakpoints.up('md')]: {
      fontSize: '40px',
      lineHeight: '50px',
      margin: '0 0 125px 0',
      padding: '0 35px'
    },
  },
}));

const Player = (url) => {
  return (
    <div className='player-wrapper '>
      <ReactPlayer
        url={url.url}
        className='react-player'
        width='100%'
        height='100%'
      />
    </div>
  );
};

const Videos = () => {
  const classes = useStyles();
  const videosUrl = [
    'https://www.youtube.com/watch?v=WHQT7GYJUno',
    'https://www.youtube.com/watch?v=5HNlvSvhgnM',
    'https://www.youtube.com/watch?v=1URZfyOgylY'
  ];

  return (
    <section className={classes.videos} id="videos">
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <h3 className={classes.title}>Video Înainte și după igienizare</h3>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
       
              <React.Fragment>
                <Grid item xs={12} sm={6}>
                  <Player url={videosUrl[0]}/>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Player url={videosUrl[1]}/>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Player url={videosUrl[2]}/>
                </Grid>
              </React.Fragment>
          
        </Grid>
      </Container>
    </section>
  );
};

export default Videos;
