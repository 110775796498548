import React, { useState, useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import TabsServices from './components/TabsServices';
import CollapseServices from './components/CollapseServices';

const useStyles = makeStyles((theme) => ({
  services: {
    [theme.breakpoints.down('md')]: {
      margin: '90px 0  50px 0'
    },
    [theme.breakpoints.up('md')]: {
      margin: '90px 0  145px 0'
    }
  },

  title: {
    fontSize: '24px',
    fontWeight: 700,
    color: '#47474C',
    textTransform: 'uppercase',
    textAlign: 'center',
    margin: '0 0 50px 0',

    [theme.breakpoints.up('sm')]: {
      fontSize: '40px',
      lineHeight: '50px'
    }
  }
}));

const Services = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await fetch('/dataprices.json');
      const json = await response.json();
      setData(json);
    };

    fetchData().catch(console.error);
  }, []);

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900 ? setIsMobile(true) : setIsMobile(false);
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());
  }, []);

  const classes = useStyles();

  return (
    <section className={classes.services} id='services'>
      <Container>
        <Grid container spacing={0}>
          <Grid item xs={12}>
            <h3 className={classes.title}>Servicii curățenie</h3>
          </Grid>

          <Grid item xs={12}>
            {!isMobile ? <TabsServices data={data} /> : <CollapseServices data={data} />}
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Services;
