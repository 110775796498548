import React from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import Rezidential from './Rezidential';
import Auto from './Auto';
import Comercial from './Comercial';
import Barci from './Barci';
import { data } from './../data';
const useStyles = makeStyles((theme) => ({
  tab: {
    background: '#47474C',
    color: '#fff',
    opacity: 1,
    margin: '0 1px',
    padding: '0 30px',
    fontSize: '18px',
    fontWeight: 600,
    flex: '1 1 auto',
    maxWidth: '23%',
    height: '110px',

    '&.Mui-selected': {
      background: '#fff',
      color: '#47474C'
    }
  },

  tabPanel: {
    background: '#fff',
    padding: '70px 50px 10px 50px',
    marginTop: '-20px'
  },

  tabPanelInner: {}
}));

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
};

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div role='tabpanel' hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <>{children}</>}
    </div>
  );
};

const TabsServices = (props) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label='tabs'
        TabIndicatorProps={{ style: { background: 'transparent' } }}
        className={classes.tabs}
        centered
      >
        {data.categories.map((item, index) => {
          return <Tab key={index} label={item.title} {...a11yProps(0)} className={classes.tab} />;
        })}
      </Tabs>

      {data.categories.map((item, index) => {
        const renderSwitch = (value) => {
          let toRet;
          switch (value) {
            case 'auto':
              toRet = <Auto data={props.data?.auto} key={0} />;
              break;
            case 'rezidential':
              toRet = <Rezidential data={props.data?.rezidential} key={1} />;
              break;
            case 'comercial':
              toRet = <Comercial key={2} />;
              break;
            case 'barci':
              toRet = <Barci key={1} />;
              break;
            default:
              return null;
          }
          return toRet;
        };

        return (
          <TabPanel key={index} value={value} index={index} className={classes.tabPanel}>
            <div className={classes.tabPanelInner}>{renderSwitch(item.label)}</div>
          </TabPanel>
        );
      })}
    </>
  );
};

export default TabsServices;
