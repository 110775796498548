import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  wrapper: {
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',

      '& > div ': {
        width: 'calc(50% - 16px)',
      },
    },
  },
  title: {
    fontSize: '18px',
    textTransform: 'uppercase',
    fontWeight: 600,
    [theme.breakpoints.down('md')]: {
      paddingBottom: '10px',
    },
  },

  divider: {
    margin: '15px 0',
  },

  card: {
    [theme.breakpoints.down('md')]: {
      marginBottom: '15px',
    },
    [theme.breakpoints.up('md')]: {
      borderRadius: 0,
      boxShadow: 'none',
      border: '1px solid #ccc',
      margin: '0 0 40px 0',
    },
  },

  price: {
    fontWeight: 600,
  },

  desc: {
    '& span': {
      fontWeight: 600,
    },

    [theme.breakpoints.up('md')]: {
      '&.extra': {
        margin: 0,
      },
    },
  },

  content: {
    [theme.breakpoints.up('sm')]: {
      padding: '40px 25px 25px 25px',
      display: 'flex',
      justifyContent: 'space-between',
      '& section': {
        //   width: 'calc(55% - 35px)',
        marginTop: '-5px',
      },
    },

    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
    [theme.breakpoints.up('lg')]: {
      '& section': {
        width: 'calc(55% - 35px)',
      },
    },
  },

  picture: {
    '& img': {
      width: '100%',
    },
    [theme.breakpoints.down('md')]: {
      margin: '0 0 30px 0',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: '125px',
    },
    [theme.breakpoints.down('xs')]: {
        marginTop: '15px'  ,
    },
    [theme.breakpoints.up('sm')]: {
      width: '45%',
      '& img': {
        width: '100%',
      },
    },
    [theme.breakpoints.between('sm', 'md')]: {
        width: '65%'
      },
  },

  wrapperFull: {
    [theme.breakpoints.down('md')]: {
      '& picture img': {
        margin: '30px 0',
      },
    },
    [theme.breakpoints.up('md')]: {
      '& picture': {
        width: '279px',
      },
      '& section': {
        width: 'calc(100% - 315px)',
      },
    },
  },
}));
