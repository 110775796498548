import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';

const bg = `${process.env.PUBLIC_URL}/images/benefits.jpg`;
const bgMobile = `${process.env.PUBLIC_URL}/images/benefits-mobile.jpg`;
const bgLeft = `${process.env.PUBLIC_URL}/images/beneficii.jpg`;

const useStyles = makeStyles((theme) => ({
  benefits: {
    background: theme.palette.primary.main,
    overflow: 'hidden',
  },
  col: {
    width: '100%',
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: '50%',
    },
  },

  colLeft: {},

  colRight: {
    order: '-1',
    [theme.breakpoints.up('sm')]: {
      order: 2,
    },
  },

  imageRight: {
    [theme.breakpoints.down('sm')]: {
      width: '100vw',
      height: '175px',
      marginLeft: '-35px',
      background: `center center no-repeat url(${bgMobile})`,
      backgroundSize: 'cover',
    },
    [theme.breakpoints.up('sm')]: {
      width: 'calc(50vw + 35px)',
      background: `center center no-repeat url(${bg})`,
      backgroundSize: 'cover',
      height: '100%',
    },
  },

  imageLeftContainer: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    height: '60px',
    right: '0',
    [theme.breakpoints.up('sm')]: {
      height: '145px',
      width: 'calc(50vw + 30px)',
      // left: '-50%',
    },
  },

  imageLeft: {
    background: `center center no-repeat url(${bgLeft})`,
    backgroundSize: 'cover',
    width: '100vw',
    marginLeft: '-35px',
    height: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '100%',
      marginLeft: 0,
    },
  },

  body: {
    margin: '30px 0 120px 0',
    [theme.breakpoints.up('sm')]: {
      margin: '165px 60px 360px 0',
    },
  },

  title: {
    fontSize: '22px',
    lineHeight: '27px',
    fontWeight: 600,
    textTransform: 'uppercase',
    color: '#fff',
    margin: '0 0 30px 0',
    paddingRight: '20px',
    [theme.breakpoints.up('sm')]: {
      fontSize: '40px',
      lineHeight: '50px',
      paddingRight: '50px',
      marginBottom: '85px',
    },
  },

  list: {
    listStyle: 'none',
    padding: 0,
    margin: 0,
    '& li': {
      display: 'flex',
      alignItems: 'center',
      position: 'relative',
      color: '#fff',
      fontSize: '18px',
      fontWeight: 500,
      lineHeight: '28px',
      paddingLeft: '35px',
      '&:before': {
        content: '""',
        position: 'absolute',
        left: 0,
        filter: 'invert(100%)',
        width: '1em',
        height: '1em',
        background: `url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pg0KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDE5LjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPg0KPHN2ZyB2ZXJzaW9uPSIxLjEiIGlkPSJDYXBhXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgNDI2LjY2NyA0MjYuNjY3IiBzdHlsZT0iZW5hYmxlLWJhY2tncm91bmQ6bmV3IDAgMCA0MjYuNjY3IDQyNi42Njc7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4NCjxnPg0KCTxnPg0KCQk8cGF0aCBkPSJNNDIxLjg3Niw1Ni4zMDdjLTYuNTQ4LTYuNzgtMTcuMzUyLTYuOTY4LTI0LjEzMi0wLjQyYy0wLjE0MiwwLjEzNy0wLjI4MiwwLjI3Ny0wLjQyLDAuNDJMMTE5LjI1NywzMzQuMzc1DQoJCQlsLTkwLjMzNC05MC4zMzRjLTYuNzgtNi41NDgtMTcuNTg0LTYuMzYtMjQuMTMyLDAuNDJjLTYuMzg4LDYuNjE0LTYuMzg4LDE3LjA5OSwwLDIzLjcxM2wxMDIuNCwxMDIuNA0KCQkJYzYuNjY1LDYuNjYzLDE3LjQ2OCw2LjY2MywyNC4xMzIsMEw0MjEuNDU2LDgwLjQ0QzQyOC4yMzYsNzMuODkxLDQyOC40MjQsNjMuMDg3LDQyMS44NzYsNTYuMzA3eiIvPg0KCTwvZz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjxnPg0KPC9nPg0KPGc+DQo8L2c+DQo8Zz4NCjwvZz4NCjwvc3ZnPg0K")`,
      },
    },

    '& li:not(:last-child)': {
      marginBottom: '30px',
    },
  },
}));

const Services = () => {
  const classes = useStyles();

  return (
    <section className={classes.benefits} id="benefits">
      <Container>
        <Grid container>
          <Grid item className={`${classes.col} ${classes.colLeft}`}>
            <div className={classes.body}>
              <h3 className={classes.title}>
                Beneficiile curățării cu abur uscat{' '}
              </h3>
              <ul className={classes.list}>
                <li>Dezinfecție și igienizare cu abur uscat fără solvenți</li>
                <li>Ecologic, fără deșeuri</li>
                <li>Abur uscat și dezinfectant Virucid</li>
                <li>Sterilizează suprafețe și utilaje</li>
                <li>Elimină bacteriile, virușii și alergenii</li>
              </ul>
            </div>
            <div className={classes.imageLeftContainer}>
              <div className={classes.imageLeft}></div>
            </div>
          </Grid>

          <Grid item className={`${classes.col} ${classes.colRight}`}>
            <div className={classes.imageRight}></div>
          </Grid>
        </Grid>
      </Container>
    </section>
  );
};

export default Services;
