import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import PhoneIcon from '@material-ui/icons/Phone';
import EmailIcon from '@material-ui/icons/Email';

import Logo from './Logo';

const useStyles = makeStyles((theme) => ({
  navigation: {
    backgroundColor: '#fff',
  },
  menu: {
    position: 'fixed',
    width: '100%',
    background: '#fff',
    zIndex: '99',
  },
  nav: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '130px',
  },
  defaultBtn: {
    backgroundColor: theme.palette.secondary,
    fontWeight: '700',
    color: theme.palette.secondary.contrastText,
    borderColor: 'transparent',
    padding: '8px 26px',
    boxShadow: 'none',
    textTransform: 'lowercase',
    '&:hover': {
      backgroundColor: theme.palette.secondary.dark,
      borderColor: 'transparent',
      boxShadow: 'none',
    },
  },
  phoneBtn: {
    marginLeft: theme.spacing(3),
    padding: '8px 30px',
  },
  mobileBtn: {
    backgroundColor: '#47474C',
    borderRadius: '12px',
    boxShadow: 'none',
    padding: '6px 10px',
    '&:hover': {
      borderColor: 'transparent',
      boxShadow: 'none',
    },
  },
  mobileBtnPhone: {
    backgroundColor: theme.palette.primary.main,
    marginRight: theme.spacing(2),
  },
  actionsArea: {
    display: 'flex',
  },
}));

const emailAddress = 'office@alsoclean.ro';
const phoneNumber = '0722 188 145';

const openEmailWindow = () => {
  window.location = `mailto:${emailAddress}`;
};

const openPhoneWindow = () => {
  window.open(`tel:${phoneNumber}`);
};

const Navbar = () => {
  const classes = useStyles();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900 ? setIsMobile(true) : setIsMobile(false);
    };

    setResponsiveness();

    window.addEventListener('resize', () => setResponsiveness());
  }, []);

  return (
    <section className={classes.navigation}>
      <nav className={classes.menu}>
        <Container>
          <Grid item>
            <div className={classes.nav}>
              <div className='logo'>
                <Logo />
              </div>

              <div className={classes.actionsArea}>
                {!isMobile ? (
                  <>
                    <Button
                      variant='contained'
                      color='secondary'
                      className={classes.defaultBtn}
                      startIcon={<EmailIcon />}
                      onClick={openEmailWindow}
                    >
                      {emailAddress}
                    </Button>
                    <Button
                      variant='contained'
                      color='secondary'
                      className={` ${classes.defaultBtn} ${classes.phoneBtn} `}
                      startIcon={<PhoneIcon />}
                      onClick={openPhoneWindow}
                    >
                      {phoneNumber}
                    </Button>
                  </>
                ) : (
                  <>
                    <span
                      className={` ${classes.mobileBtn} ${classes.mobileBtnPhone} `}
                      onClick={openPhoneWindow}
                    >
                      <PhoneIcon style={{ fill: '#fff' }} fontSize='large' />
                    </span>

                    <span
                      className={classes.mobileBtn}
                      onClick={openEmailWindow}
                    >
                      <EmailIcon style={{ fill: '#fff' }} fontSize='large' />
                    </span>
                  </>
                )}
              </div>
            </div>
          </Grid>
        </Container>
      </nav>
    </section>
  );
};

export default Navbar;
