import React from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ScrollToTop from './components/ScrollToTop';
import Home from './Home';
import Gdpr from './Gdpr';

const gaTracker = () => {
  const GA_ID = 'UA-199147094-1';
  ReactGA.initialize(GA_ID);
  ReactGA.pageview(window.location.pathname + window.location.search);
};

const App = () => {
  gaTracker();

  return (
    <Router>
      <>
        <ScrollToTop />
        <Switch>
          <Route exact path='/' component={Home} />
          <Route path='/gdpr' component={Gdpr} />
        </Switch>
      </>
    </Router>
  );
};

export default App;
