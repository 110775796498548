import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Rezidential from './Rezidential';
import Auto from './Auto';
import Comercial from './Comercial';
import Barci from './Barci';
import { data } from './../data';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .Mui-expanded': {
      boxShadow: 'none'
    }
  },
  summary: {
    background: '#47474C',
    boxShadow: 'none',
    borderBottom: '1px solid #707070',
    color: '#fff',
    paddinfTop: '5px',
    paddingBottom: '5px',
    '& .MuiIconButton-label': {
      color: '#fff'
    },
    '&.Mui-expanded': {
      background: '#fff',
      color: '#47474C',
      '& .MuiIconButton-label': {
        color: '#47474C'
      }
    }
  },

  heading: {
    textTransform: 'capitalize',
    fontSize: '20px',
    fontWeight: 600
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary
  },

  content: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    padding: '0',
    background: '#f8f8f8'
  },

  contentInner: {
    width: '100%'
  },

  card: {
    marginBottom: '15px'
  }
}));

const CollapseServices = (props) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <div className={classes.root}>
      {data.categories &&
        data.categories.map((item, index) => {
          const panelId = `panel${index}}`;
          const isExpanded = index < 1;

          const renderSwitch = (value) => {
            let toRet;
            switch (value) {
              case 'auto':
                toRet = <Auto data={props.data?.auto} key={0} />;
                break;
              case 'rezidential':
                toRet = <Rezidential data={props.data?.rezidential} key={1} />;
                break;
              case 'comercial':
                toRet = <Comercial key={2} />;
                break;
              case 'barci':
                toRet = <Barci key={1} />;
                break;
              default:
                return null;
            }
            return toRet;
          };

          return (
            <Accordion key={index} square defaultExpanded={isExpanded} expanded={expanded === panelId} onChange={handleChange(panelId)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${index}bh-content`}
                id={`panel${index}bh-header`}
                className={classes.summary}
              >
                <Typography className={classes.heading}>{item.title}</Typography>
              </AccordionSummary>
              <AccordionDetails className={classes.content}>{renderSwitch(item.label)}</AccordionDetails>
            </Accordion>
          );
        })}
    </div>
  );
};

export default CollapseServices;
