import Card from '@material-ui/core/Card';
import Divider from '@material-ui/core/Divider';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { useStyles } from './styles';

const Barci = () => {
  const classes = useStyles();
  return (
    <div className={classes.wrapperFull}>
      <Card className={classes.card}>
        <CardContent className={classes.content}>
          <>
            <picture className={classes.picture}>
              <img
                src={`${process.env.PUBLIC_URL}/images/iaht.png`}
                alt='barci, iahturi, avioane'
                loading="lazy"
                className={classes.img}
              />
            </picture>
            <section>
              <Typography className={classes.title}>
                ambarcațiuni și avioane
              </Typography>
              <Divider className={classes.divider} />
              <Typography className={classes.desc}>
                Tarifele se stabilesc individual la cerere
              </Typography>
            </section>
          </>
        </CardContent>
      </Card>
    </div>
  );
};

export default Barci;
